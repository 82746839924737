import React, { useCallback, useEffect } from 'react'
import { CustomArrowProps } from 'react-slick'
import { isEqual } from 'lodash-es'
import cn from 'classnames'

import { Button } from 'ui/button'

import { Container } from 'components/container'
import { SlickSlider } from 'components/slick-slider'
import { ProductCard } from 'components/product-card'
import { ExposureTracker } from 'components/exposure-tracker'
import { DestinationCard } from 'components/destination-card'
import { CollectionCard } from 'components/collection-card'
import { GridProps } from 'components/grid'
import { PillsModifier } from 'components/modifiers/pills'
import { DynamicComponentProps } from 'components/dynamic-components/types'
import { InterestCard } from 'components/interest-card'
import { PoiCard } from 'components/poi-card-vertical'
import { Link } from 'components/link'

import { getProductMerchandiseLabel } from 'page-modules/product/utils'

import useTranslation from 'lib/hooks/useTranslation'

import { isBrowser, noop } from 'lib/utils'
import { useGlobalContext } from 'lib/context/global-context'
import { ComponentModifier } from 'lib/@Types'

import { EVENTS } from 'lib/constants/events'
import { MOBILE_VIEW_BREAKPOINT } from 'lib/constants'

import s from './styles.module.scss'

export interface CardSliderProps extends DynamicComponentProps {
  trackEvent?: TrackEventType
  loading?: boolean
  headingClassname?: string
  className?: string
  onButtonClick?: () => void
  cardType?: 'product' | 'destination' | 'collection' | 'poi' | 'interest' | 'destinationAsPoi'
  variant?: 'pdp'
  itemPerSlide?: number
  showProductCallout?: boolean
  asGrid?: GridProps & {
    enable?: boolean
  }
}

// TODO: while copying in SSR, rename this component name as used by Product, Destination & Collection
const CardSlider = ({
  componentEventId,
  componentContent = {},
  componentQueryData = {},
  modifierManager,
  className,
  loading,
  onButtonClick = noop,
  cardType = 'product',
  trackEvent = noop,
  headingClassname,
  pageName,
  componentId,
  componentMetaType,
  componentType,
  componentRank,
  variant,
  itemPerSlide = 4,
  showProductCallout,
  ...props
}: CardSliderProps) => {
  const { t } = useTranslation(['product'])
  const { isMobileView, isTabletView } = useGlobalContext()
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [isLastItemInSlider, setIsLastItemInSlider] = React.useState(false)
  const {
    products = [],
    productCount,
    destinations = [],
    destinationCount,
    collections: poi,
  } = componentQueryData
  const { header, subHeader, subTitle, link, items: collections = [] } = componentContent

  // Pass additional trackevents for country and destination page only
  const passAdditionalTrackEventData = pageName === 'country' || pageName === 'destination'

  const pillModifier = modifierManager?.list?.find?.((modifier) => modifier.type === 'pills') as
    | ComponentModifier<'pills'>
    | undefined

  const DEFAULT_SLIDER_CONFIG = {
    rows: 1,
    itemsPerSlide: isMobileView ? 2 : isTabletView ? 3 : itemPerSlide,
    partialItemVisibility: 0.19,
  }
  const cardSchema: any = {
    product: {
      component: ProductCard,
      props: {
        items: products,
        itemCount: productCount,
      },
      trackEvent: {
        name: EVENTS.PRODUCT,
        attributeValueId: 'productId',
        container: `${componentEventId}_products`,
      },
    },
    destination: {
      component: DestinationCard,
      props: {
        items: destinations,
        itemCount: destinationCount,
      },
      trackEvent: {
        name: EVENTS.DESTINATION,
        attributeKeyId: 'destinationId',
        attributeValueId: 'destinationId',
      },
    },
    collection: {
      component: CollectionCard,
      props: {
        items: collections,
        itemCount: 0, // 0 as don't want to show count header for collections
      },
      trackEvent: {
        name: EVENTS.COLLECTION,
        attributeValueId: 'header',
      },
      aspectRatio: 1,
      sliderConfig: {
        rows: isMobileView ? 2 : DEFAULT_SLIDER_CONFIG.rows,
        itemsPerSlide: isMobileView ? 1 : DEFAULT_SLIDER_CONFIG.itemsPerSlide,
        partialItemVisibility: 0.55,
      },
    },

    interest: {
      component: InterestCard,
      props: {
        items: collections,
        itemCount: 0, // 0 as don't want to show count header for collections
      },
      trackEvent: {
        name: EVENTS.COLLECTION,
        attributeKeyId: 'id',
        attributeValueId: 'id',
      },
      aspectRatio: 0.7,
      sliderConfig: {
        rows: DEFAULT_SLIDER_CONFIG.rows,
        itemsPerSlide: 8,
        partialItemVisibility: 0,
      },
    },
    poi: {
      component: PoiCard,
      props: {
        items: poi,
        itemCount: 0, // 0 as don't want to show count header for collections
      },
      trackEvent: {
        name: EVENTS.COLLECTION,
        attributeKeyId: 'id',
        attributeValueId: 'collectionId',
      },
      aspectRatio: 16 / 9,
      sliderConfig: {
        rows: DEFAULT_SLIDER_CONFIG.rows,
        itemsPerSlide: 1,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        noResponsive: true,
      },
    },
    destinationAsPoi: {
      component: PoiCard,
      props: {
        items: destinations,
        itemCount: 0, // 0 as don't want to show count header for collections
      },
      trackEvent: {
        name: EVENTS.DESTINATION,
        attributeKeyId: 'id',
        attributeValueId: 'destinationId',
      },
      aspectRatio: 16 / 9,
      sliderConfig: {
        rows: DEFAULT_SLIDER_CONFIG.rows,
        itemsPerSlide: 1,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        noResponsive: true,
      },
    },
  }
  const {
    component: CardComponent,
    props: cardItems,
    aspectRatio,
    sliderConfig = DEFAULT_SLIDER_CONFIG,
    trackEvent: trackEventDetails,
  } = cardSchema[cardType]
  const { itemsPerSlide, rows, partialItemVisibility, slidesToShow, slidesToScroll, noResponsive } =
    sliderConfig
  const { name: cardTypeEventName, attributeValueId, attributeKeyId = 'id', container } = trackEventDetails
  const contentCardsStyles: any = { '--items-per-row': itemsPerSlide }

  const sliderSettings: any = {
    speed: 500,
    draggable: false,
    arrows: !isMobileView,
    slidesToShow: slidesToShow || itemsPerSlide,
    slidesToScroll: slidesToScroll || itemsPerSlide,
    infinte: false,
    useTransform: false,
    responsive: noResponsive
      ? []
      : [
          {
            breakpoint: MOBILE_VIEW_BREAKPOINT,
            settings: {
              slidesToShow: itemsPerSlide + partialItemVisibility,
              centerPadding: 0,
            },
          },
        ],
    rows,
  }

  const isDestinationCard = cardType === 'destination'
  const isProductCard = cardType === 'product'
  const isRecentlyViewed = componentEventId === 'recently_viewed'

  const shouldShowProductCallout = isProductCard && showProductCallout

  const onAllDestinationClick = () => {
    trackEvent?.({
      attributeId: EVENTS.DESTINATIONS_SHOW_ALL,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
    })
    onButtonClick?.()
  }

  const onCardClick = useCallback(
    (data: any, index: number) => {
      trackEvent({
        attributeId: componentEventId,
        attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
        attributeValue: {
          [attributeKeyId]: data[attributeValueId],
          cardIndex: index,
          cardType: cardTypeEventName,
          trackMeta: data.trackMeta || {},
          searchScore: data.searchScore,
          destinationId: data.destinationId,
        },
        container: container || componentEventId,
      })
    },
    [componentEventId, attributeKeyId, attributeValueId, cardTypeEventName, container, trackEvent]
  )

  const renderCard = (data: any, index: number) => {
    const callout =
      products.length > 1 && index === 0 && shouldShowProductCallout
        ? t('bestDeal', { ns: 'product' })
        : undefined

    return (
      <CardComponent
        key={data[attributeValueId]}
        indexInList={index}
        data={data}
        {...(!isDestinationCard && { showDestination: componentContent.display_destination_name })}
        {...((cardType === 'poi' || cardType === 'destinationAsPoi') && {
          ctaText: componentContent?.card?.link?.label,
        })}
        isSliderItem
        // below mobile ratio taken from figma
        aspectRatio={aspectRatio || (isMobileView ? 37 / 44 : null)}
        onClick={() => onCardClick(data, index)}
        callout={callout}
        size={isProductCard && isMobileView && isRecentlyViewed ? 'xsmall' : undefined}
      />
    )
  }

  const renderHeading = () => {
    const headerAsString = isDestinationCard || cardType == 'interest'
    if ((isDestinationCard && isMobileView) || !header || (!headerAsString && !Array.isArray(header)))
      return null

    return (
      <h3 className={cn(variant !== 'pdp' && s.headingText, headingClassname)}>
        {headerAsString ? (
          <span>{header}</span>
        ) : (
          <>
            <span>{header[0] || ''}&nbsp;</span>
            <span>{header[1] || ''}</span>
          </>
        )}
      </h3>
    )
  }

  const renderCountsHeader = () => {
    // show subheader in case item counts are more than 5
    if (isDestinationCard || !subHeader || !cardItems.itemCount || cardItems.itemCount <= 4) return null

    return (
      <span className={s.activityCount}>
        {cardItems.itemCount} {subHeader}
      </span>
    )
  }

  const renderSubHeader = () => {
    if (!subTitle) return null

    return (
      <div className={s.subHeaderText}>
        <span>{subTitle}</span>
      </div>
    )
  }

  const renderCountsCtaForDesktop = () => {
    if (!link) return null

    const { label, url } = link
    return (
      <span
        onClick={() =>
          trackEvent({ attributeId: componentEventId, attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON })
        }
      >
        <Link href={`/${url}`} passHref>
          <Button variant="link-tertiary" className={cn(s.seeAllCtaDesktop, s.rightAlign)}>
            {label} ({cardItems.itemCount})
          </Button>
        </Link>
      </span>
    )
  }

  const renderCountsCtaForMobile = () => {
    if (!isMobileView || !link) return null

    const { label, url } = link
    return (
      <span
        onClick={() =>
          trackEvent({ attributeId: componentEventId, attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON })
        }
      >
        <Link href={`/${url}`} passHref>
          <div className={s.seeAllCtaMobileContainer}>
            <Button variant="secondary" fluid={isMobileView} size="medium" disabled={loading}>
              <span>
                {label}
                {loading ? undefined : ` (${cardItems.itemCount})`}
              </span>
            </Button>
          </div>
        </Link>
      </span>
    )
  }

  const PreviousArrow = ({ onClick }: CustomArrowProps) => {
    if (isMobileView) return null
    // note: need above condition bcz we don't use default className props(onClick above used only) of CustomArrowProps to hide in mobile

    return (
      <Button
        aria-label="previous"
        disabled={activeSlideIndex === 0}
        variant="tertiary-outline"
        shape="circle"
        iconName="caret-left"
        size="large"
        className={cn(s.sliderArrow, s.sliderArrowPrevious)}
        onClick={onClick}
      />
    )
  }

  // note: can't fuse this method with above as no any other custom props are accepted by slick slider in custom arrow
  // to support conditional value read from custom props
  const totalItems = cardItems.items?.length
  const NextArrow = ({ onClick }: CustomArrowProps) => {
    if (isMobileView) return null
    // note: need above condition bcz we don't use default className props(onClick above used only) of CustomArrowProps to hide in mobile

    return (
      <Button
        aria-label="next"
        disabled={isLastItemInSlider}
        variant="tertiary-outline"
        shape="circle"
        iconName="caret-right"
        size="large"
        className={cn(s.sliderArrow, s.sliderArrowNext)}
        onClick={onClick}
      />
    )
  }

  useEffect(() => {
    // On cardItems update, slick slider doesn't update the page number. This doesnt make
    // slides and arrows in sync. To fix this, set the activeSlide index on item change.
    // For ex, Recently view component (RVC) items gets updated on item click as newly opened item will
    // be 1st item on RVC. So SlickSliders updates the cardItems but not updating the state of arrows.
    setActiveSlideIndex(0)
  }, [cardItems.items])

  if (isBrowser && !loading && !cardItems.items?.length) return null

  return (
    <Container
      className={cn(s.cardSliderContainer, className, s[`${cardType}Cards`])}
      data-component-type={props['data-component-type']}
    >
      <div className={cn(s.headerRow, { [s.withModifiers]: pillModifier?.data?.length })}>
        <div className={s.leftColumn}>
          {renderHeading()}
          {renderSubHeader()}
          {!!pillModifier?.data?.length ? (
            <PillsModifier
              variant="light"
              modifier={pillModifier}
              size={isMobileView ? 'medium' : 'large'}
              fullWidth={false}
              componentEventId={componentEventId}
              onChange={(item) => {
                modifierManager?.update({
                  current: item,
                  type: 'pills',
                  modifierId: pillModifier.modifierId,
                })
                setActiveSlideIndex(0)
              }}
              trackEvent={trackEvent}
            />
          ) : null}
        </div>

        {!isMobileView && !loading && (
          <div className={s.rightColumn}>
            {renderCountsHeader()}
            {renderCountsCtaForDesktop()}
          </div>
        )}
      </div>

      {loading ? (
        <SlickSlider key="loader" {...sliderSettings} className={s.cardSlider}>
          {[1, 2, 3, 4]?.map((index: number) => (
            <div key={index} className={s.cards}>
              <div style={contentCardsStyles}>
                <CardComponent loading aspectRatio={aspectRatio || (isMobileView ? 37 / 44 : null)} />
              </div>
            </div>
          ))}
        </SlickSlider>
      ) : (
        /* react-slider sets invalid left position in mobile view if SlickSlider called before data (cardItems) received */
        cardItems.items?.length > 0 && (
          <SlickSlider
            {...sliderSettings}
            className={s.cardSlider}
            beforeChange={(currentSlide: number, nextSlide: number) => {
              // fix: condition below for slickslider lib sometimes call this callback method upon component load
              // which result into unnecessory event trigger
              // when this method triggers on component load, both index has same value to identify unnecessory trigger
              // in practical both index can't be same as user has only option to click next or previous button
              if (currentSlide === nextSlide) {
                return
              }
              trackEvent({
                attributeId: componentEventId,
                attributeType: EVENTS.ATTRIBUTES_TYPE.SLIDER,
                direction: nextSlide < currentSlide ? 'left' : 'right',
                container: componentEventId,
              })

              setActiveSlideIndex(nextSlide)
            }}
            prevArrow={<PreviousArrow />}
            nextArrow={<NextArrow />}
          >
            {cardItems.items?.map((product: any, index: number) => {
              return (
                <div key={product.id || product.productId} className={s.cards}>
                  <div style={contentCardsStyles}>{renderCard(product, index)}</div>
                </div>
              )
            })}
          </SlickSlider>
        )
      )}

      {isDestinationCard && isMobileView && (
        <div className={cn('flex-center', s.mobileCtaBtn)}>
          <Button
            variant="secondary"
            size={isMobileView ? 'medium' : 'large'}
            onClick={onAllDestinationClick}
          >
            {subHeader}
          </Button>
        </div>
      )}
      {/* Skip Triggering ExposureTracker when data is loading, otherwise it will record incorrect data */}
      {!loading && (
        <ExposureTracker
          onExposure={() => {
            // add rows in total only if rows in slider are > 1 to add cards of other rows in trackevent expose
            const startIndex = rows > 1 ? activeSlideIndex * rows * itemsPerSlide : activeSlideIndex
            const endIndex = rows > 1 ? startIndex + rows * itemsPerSlide : startIndex + itemsPerSlide
            const visibleCards: Product[] = cardItems.items?.slice(startIndex, endIndex)
            trackEvent({
              attributeId: componentEventId,
              eventType: EVENTS.TYPE.EXPOSURE,
              container: componentEventId,
              attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
              attributeValue: {
                cardType: cardTypeEventName,
                cards: visibleCards.map((cardItem: any, i) => {
                  return {
                    [attributeKeyId]: cardItem[attributeValueId],
                    cardIndex: activeSlideIndex + i,
                    ...(cardType === 'destination'
                      ? { destinationRank: cardItem.destinationRank }
                      : {
                          trackMeta: cardItem.trackMeta || {},
                          searchScore: cardItem.searchScore,
                          destinationId: cardItem.destinationId,
                        }),
                    ...(cardType === 'product' && getProductMerchandiseLabel(cardItem?.productTags)),
                  }
                }),
                ...(passAdditionalTrackEventData && {
                  componentId,
                  componentMetaType,
                  componentType,
                  componentRank,
                }),
              },
            })

            setIsLastItemInSlider(
              isEqual(cardItems.items[totalItems - 1], visibleCards[visibleCards.length - 1])
            )
          }}
          deps={[activeSlideIndex]}
          bottomOffset={isMobileView && link ? '148px' : '72px'}
          // 148px -> mobile only including CTA link
          // 72p -> without CTA link
        />
      )}
      {renderCountsCtaForMobile()}
    </Container>
  )
}

export { CardSlider }
