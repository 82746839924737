import React from 'react'
import cn from 'classnames'

import { useDestinationSwitcher } from 'components/destination-switcher/hooks'
import { Link } from 'components/link'

import { buildPath } from 'lib/utils'

import { DESTINATION_ROUTE_V1 } from 'lib/constants/routes'

import s from './styles.module.scss'

interface DestinationLinkProps extends React.DOMAttributes<HTMLAnchorElement> {
  className?: string
  children: React.ReactNode
  destinationId?: string
  underline?: boolean
}

const DestinationLink = ({
  className,
  children,
  destinationId,
  underline = true,
  ...restProps
}: DestinationLinkProps) => {
  const { getDestinationInfo } = useDestinationSwitcher()
  const { destination_uri: destinationUri, destination_code: destinationCode } =
    (destinationId && getDestinationInfo(destinationId)) || {}

  return (
    <Link
      href={buildPath(DESTINATION_ROUTE_V1, {
        destinationId: destinationUri,
        destinationCode: `${destinationCode}`,
      })}
    >
      <a className={cn(s.link, className, { [s.underline]: underline })} {...restProps}>
        {children}
      </a>
    </Link>
  )
}

export { DestinationLink }
