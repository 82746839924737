import { useEffect } from 'react'
import { Trans } from 'next-i18next'
import cn from 'classnames'

import { Button } from 'ui/button'
import { DrawerDialog } from 'ui/drawer-dialog'

import { ExternalLink } from 'components/external-link'

import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'
import { BEST_PRICE_GUARANTEED_HELP_TICKET_LINK, BEST_PRICE_GUARANTEED_PROMOTION_LINK } from 'lib/constants'

import s from './styles.module.scss'

const BestPriceGuaranteedModal = ({
  open,
  onClose,
  trackEvent,
}: {
  open: boolean
  onClose: () => void
  trackEvent: TrackEventType
}) => {
  const { isMobileView } = useGlobalContext()
  const { t, tp } = useTranslation('common')

  useEffect(() => {
    open &&
      trackEvent({
        attributeId: EVENTS.BEST_PRICE_GUARANTEED,
        attributeType: EVENTS.ATTRIBUTES_TYPE.POPUP,
        eventType: EVENTS.TYPE.EXPOSURE,
      })
  }, [open, trackEvent])

  return (
    <DrawerDialog
      variant={isMobileView ? 'large' : 'medium'}
      asBottomSheet={isMobileView}
      header={t('action.bestPriceGuaranteed')}
      {...(isMobileView && { animation: 'slideUp' })}
      open={open}
      onClose={onClose}
      onClickOutside={onClose}
      onEscKeyPress={onClose}
    >
      <div>
        <div className={s.title}>{tp('t.wePromiseRefundTheDifferenceViaPromoCode')}</div>
        <ol className={s.ol}>
          <li>
            {t('t.gatherYourInformation')}
            <ol>
              <li>{tp('msg.youFoundALowerPriceOnOtherTravelExperiencesWebsite')}</li>
            </ol>
          </li>
          <li>
            {t('t.sendItToUs')}
            <ol>
              <li>
                <Trans
                  ns="common"
                  i18nKey="msg.fillUpFormWithScreenshotAndWebsiteUrl"
                  components={{
                    form_link: (
                      <ExternalLink
                        className={cn('tertiary-link', s.regularText)}
                        href={BEST_PRICE_GUARANTEED_HELP_TICKET_LINK}
                      />
                    ),
                  }}
                />
                {t('lang.punctuation')}
              </li>
            </ol>
          </li>
          <li>
            {t('t.weWillReview')}
            <ol>
              <li>{tp('msg.ourCustomerCareTealWillReviewAppication')}</li>
            </ol>
          </li>
          <li>
            {t('t.receiveYourFund')}
            <ol>
              <li>{tp('msg.ifEverythingChecksOutWeWillRefundTheDifferenceViaPromoCode')}</li>
            </ol>
          </li>
        </ol>

        <p>*{tp('msg.pleaseNoteAllClaimMustBeSubmitted')}</p>

        <Button className="mt-2" variant="link-tertiary" size="small">
          <ExternalLink href={BEST_PRICE_GUARANTEED_PROMOTION_LINK}>
            {t('action.readOurTermsAndConditions', { ns: 'common' })}
          </ExternalLink>
        </Button>
      </div>
    </DrawerDialog>
  )
}

export { BestPriceGuaranteedModal }
